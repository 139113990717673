import * as React from 'react'
import {
  Table,
  Body,
  Head,
  HeadingCell,
  Row,
  Cell
} from '@toasttab/buffet-pui-table'
import cx from 'classnames'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { UserActions } from './UserActions'
import type { PayrollCustomerUser } from '@local/api/generated/gql/graphql'

const ROWS = [
  { title: 'User UUID', key: 'id' },
  { title: 'Username', key: 'username' },
  { title: 'Email', key: 'email' },
  { title: 'Toast Identity GUID', key: 'toastIdentityGuid' },
  { title: 'Shard', key: 'shardName' },
  { title: 'Customer ID', key: 'customerId' },
  { title: 'Company Code', key: 'companyCode' },
  { title: 'Customer Name', key: 'customerName' }
] as const

const empty = <div style={{ color: '#888' }}>-</div>

const loading = <div style={{ color: '#888' }}>Loading...</div>

type Props = {
  dbUser?: PayrollCustomerUser
  dexUser?: PayrollCustomerUser
  isDbLoading?: boolean
  isDexLoading?: boolean
}

const UserComparisonTable = (props: Props) => {
  const { dbUser, dexUser, isDbLoading, isDexLoading } = props

  const rows = ROWS.map(({ title, key }) => {
    const dbValue = dbUser ? dbUser[key] : undefined
    const dexValue = dexUser ? dexUser[key] : undefined

    const dbDisplayValue = isDbLoading ? loading : dbUser ? dbValue : empty
    const dexDisplayValue = isDexLoading ? loading : dexUser ? dexValue : empty

    const misMatch = !isDbLoading && !isDexLoading && dbValue !== dexValue
    return (
      <Row key={key}>
        <Cell>
          <div className='text-secondary type-default'>{title}</div>
        </Cell>
        <Cell>
          <div style={{ color: misMatch ? 'orange' : undefined }}>
            {dbDisplayValue}
          </div>
        </Cell>
        <Cell>
          <div style={{ color: misMatch ? 'orange' : undefined }}>
            {dexDisplayValue}
          </div>
        </Cell>
      </Row>
    )
  })

  const user = dbUser || dexUser

  const label = user ? `${user.shardName}  /  ${user.companyCode}` : ''

  return (
    <CardContainer noPadding>
      <Table className='table-fixed' valign='middle' variant='plain'>
        <Head>
          <Row>
            <HeadingCell className={cx('text-secondary type-default')}>
              <b>{label}</b>
            </HeadingCell>
            <HeadingCell className={cx('text-secondary type-default')}>
              <b>DB</b>
            </HeadingCell>
            <HeadingCell className={cx('text-secondary type-default')}>
              <div className='flex flex-row justify-between items-center'>
                <b>DEX</b>
                <UserActions user={dbUser || dexUser} />
              </div>
            </HeadingCell>
          </Row>
        </Head>
        <Body>{rows}</Body>
      </Table>
    </CardContainer>
  )
}

export { UserComparisonTable }
