import * as React from 'react'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { GenericForm } from '../components/generic-form'

import { useSyncUserMutation } from '@local/api/hooks'

type Values = {
  username: string
}

type Props = {
  onClose: () => void
  initialValues?: Partial<Values>
}

const SyncModal = (props: Props) => {
  const { onClose, initialValues: init } = props

  const mutation = useSyncUserMutation()

  return (
    <GenericForm
      onDone={onClose}
      onSave={async (variables) => {
        const { username } = variables

        await mutation.mutateAsync({ username })

        onClose()
      }}
      formId='sync-form'
      title='Sync user'
      initialValues={{
        username: init?.username || ''
      }}
    >
      <TextInputField
        testId='username'
        name='username'
        label='Username'
        disabled={!!init?.username}
      />
    </GenericForm>
  )
}

export { SyncModal }
