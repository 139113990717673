import * as React from 'react'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { SearchIcon } from '@toasttab/buffet-pui-icons'
import { SearchInput } from '@toasttab/buffet-pui-text-input'
import { PayrollCustomerUsersDocument } from '@local/api/generated/gql/graphql'
import { useQuery } from '@apollo/client'
import { UsersTables } from './tables/UsersTables'
import { UsersComparisonTables } from './tables/UsersComparisonTables'
import { UsersJsonTable } from './tables/UsersJsonTable'
import { EcTabs } from '@toasttab/ec-ui-tabs'
import { Select } from '@toasttab/buffet-pui-select'

const SEARCH_TYPE_OPTIONS = [
  { label: 'Username', value: 'username' },
  { label: 'User UUID', value: 'userId' },
  { label: 'Toast Identity Guid', value: 'toastIdentityGuid' }
] as const

const SEARCH_TYPES = SEARCH_TYPE_OPTIONS.map((option) => option.value)

type OptionValues = typeof SEARCH_TYPES

type SearchType = OptionValues[number]

const isSearchType = (val: string | null | undefined): val is SearchType => {
  if (!val) return false
  return SEARCH_TYPES.includes(val as SearchType)
}

const VIEWS = [
  { label: 'Data', value: 'data' },
  { label: 'Comparison', value: 'comparison' },
  { label: 'JSON', value: 'json' }
]

const VIEW_TYPES = VIEWS.map((option) => option.value)

type ViewValues = typeof VIEW_TYPES

type ViewType = ViewValues[number]

const isViewType = (val: string | undefined | null): val is ViewType => {
  if (!val) return false
  return VIEW_TYPES.includes(val as ViewType)
}

type State = {
  searchType: SearchType
  searchTerm: string
}

function UserDebugList() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = useMemo(() => {
    const rawKind = searchParams.get('searchType')
    const rawView = searchParams.get('view')

    const kind = isSearchType(rawKind) ? rawKind : 'username'
    const view = isViewType(rawView) ? rawView : 'data'
    const term = searchParams.get('searchTerm') || ''
    return { searchTerm: term, searchType: kind, view }
  }, [searchParams])

  const { searchTerm, searchType, view } = params

  const dbQuery = useQuery(PayrollCustomerUsersDocument, {
    variables: { input: { source: 'DB', [searchType]: searchTerm } },
    skip: !searchTerm
  })

  const dexQuery = useQuery(PayrollCustomerUsersDocument, {
    variables: { input: { source: 'DEX', [searchType]: searchTerm } },
    skip: !searchTerm
  })

  const [newState, setNewState] = useState<State>({
    searchType,
    searchTerm
  })

  const onSearch = () => setSearchParams({ ...params, ...newState })

  const isLoading = dbQuery.loading || dexQuery.loading

  const hasChanged =
    searchType !== newState.searchType || searchTerm !== newState.searchTerm

  const dbUsers = dbQuery.data?.payrollCustomerUsers || []
  const dexUsers = dexQuery.data?.payrollCustomerUsers || []

  return (
    <>
      <div className='flex gap-3'>
        <Select
          aria-label={'Search Type'}
          options={[...SEARCH_TYPE_OPTIONS]}
          value={newState.searchType}
          onChange={(val: any) => {
            setNewState((prev) => ({ ...prev, searchType: val }))
          }}
        />
        <div className='grow'>
          <SearchInput
            value={newState.searchTerm}
            onChange={(event) => {
              setNewState((prev) => {
                return { ...prev, searchTerm: event.target.value }
              })
            }}
            placeholder='Search'
            onKeyDown={(event) => {
              if (event.key === 'Enter') onSearch()
            }}
          />
        </div>
        <Button
          disabled={!hasChanged}
          onClick={onSearch}
          iconLeft={<SearchIcon accessibility='decorative' />}
        >
          {isLoading ? 'Searching...' : 'Search'}
        </Button>
      </div>

      <div className='pt-2'>
        <EcTabs>
          {VIEWS.map(({ label, value }) => {
            return (
              <EcTabs.Tab
                key={value}
                isActive={view === value}
                onClick={() => setSearchParams({ ...params, view: value })}
              >
                {label}
              </EcTabs.Tab>
            )
          })}
        </EcTabs>
      </div>
      {view === 'data' && (
        <UsersTables
          dbUsers={dbUsers}
          dexUsers={dexUsers}
          isDbLoading={dbQuery.loading}
          isDexLoading={dexQuery.loading}
          searchTerm={searchTerm}
        />
      )}

      {view === 'comparison' && (
        <UsersComparisonTables
          dbUsers={dbUsers}
          dexUsers={dexUsers}
          isDbLoading={dbQuery.loading}
          isDexLoading={dexQuery.loading}
          searchTerm={searchTerm}
        />
      )}

      {view === 'json' && (
        <UsersJsonTable dbUsers={dbUsers} dexUsers={dexUsers} />
      )}
    </>
  )
}

export { UserDebugList }
