import * as React from 'react'
import { useMemo } from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { PayrollCustomerUser } from '@local/api/generated/gql/graphql'

const stringify = (val: unknown) => {
  try {
    return JSON.stringify(val, null, 3)
  } catch {
    return ''
  }
}

type Props = {
  dbUsers?: PayrollCustomerUser[]
  dexUsers?: PayrollCustomerUser[]
  isDbLoading?: boolean
  isDexLoading?: boolean
}

const UsersJsonTable = (props: Props) => {
  const { dbUsers, dexUsers } = props

  const dbUserResp = useMemo(() => stringify(dbUsers), [dbUsers])

  const dexUserResp = useMemo(() => stringify(dexUsers), [dexUsers])

  return (
    <div className='flex flex-col gap-4 py-4'>
      <CardContainer>
        <h2>DB Users</h2>
        <div className='font-mono whitespace-pre-wrap'>{dbUserResp}</div>
      </CardContainer>

      <CardContainer>
        <h2>Dex Users</h2>
        <div className='font-mono whitespace-pre-wrap'>{dexUserResp}</div>
      </CardContainer>
    </div>
  )
}

export { UsersJsonTable }
