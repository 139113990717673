import { PayrollCustomerUser } from '@local/api/generated/gql/graphql'
import { LinkModal } from './LinkModal'
import { UnlinkModal } from './UnlinkModal'
import { SyncModal } from './SyncModal'
import { EventsModal } from './EventsModal'
import * as React from 'react'

export type Modals = 'link' | 'unlink' | 'sync' | 'events' | undefined

export const UserActionsModal = (props: {
  modal: Modals
  onClose: () => void
  user: PayrollCustomerUser
}) => {
  const { modal, onClose, user } = props

  switch (modal) {
    case 'link':
      return (
        <LinkModal
          initialValues={{ userId: user?.id, customerId: user?.customerId }}
          onClose={onClose}
        />
      )
    case 'unlink':
      return (
        <UnlinkModal
          initialValues={{ userId: user?.id, customerId: user?.customerId }}
          onClose={onClose}
        />
      )
    case 'sync':
      return (
        <SyncModal
          initialValues={{ username: user?.username }}
          onClose={onClose}
        />
      )
    case 'events':
      return <EventsModal onClose={onClose} userId={user?.id} />
    default:
      return <></>
  }
}
