import * as React from 'react'
import { BrowserRouter, useRoutes, Navigate } from 'react-router-dom'
import { ApolloProvider, useQuery } from '@apollo/client'
import { QueryClientProvider } from '@tanstack/react-query'

import { Error500DesktopIllustration } from '@toasttab/buffet-pui-illustrations'

import { useUser } from '@toasttab/ec-session'
import { PortalProvider } from '@toasttab/ec-layout'

import { client, queryClient } from '@local/api/client'
import { ActorPermissionsForEmployeesDocument } from '@local/api/generated/gql/graphql'

import { EntryRoute } from './routes'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

//tell the router that it's ok to load anywhere but not do anything
const wildcard = {
  path: '*',
  element: <Navigate to='/user/debug' />
}

//this is the whole app. the routes do 99% of the work
const App = () => useRoutes([EntryRoute, wildcard])

const AdminOnly = ({ children }: React.PropsWithChildren<{}>) => {
  const user = useUser()

  const { data, loading } = useQuery(ActorPermissionsForEmployeesDocument, {
    variables: {
      employeeIds: [user.employeeUuid]
    },
    skip: !user.employeeUuid
  })

  if (loading) {
    return (
      <div className='w-full flex flex-col items-center mt-12 space-y-8'>
        <MerryGoRound />
        <div className='text-center type-headline-3'>Loading...</div>
      </div>
    )
  }

  const permissions =
    data?.actorPermissionsForEmployees?.find(
      (item) => item.employeeId === user.employeeUuid
    )?.permissions ?? []

  if (permissions.includes('MIGRATE_USERS')) {
    return <>{children}</>
  }

  return (
    <div className='flex flex-col items-center space-y-8'>
      <Error500DesktopIllustration />
      <div className='text-center type-headline-3'>
        This user does not have access
      </div>
    </div>
  )
}

/**
 * This is the full application
 */
const AppBootstrap = () => (
  <ApolloProvider client={client}>
    <QueryClientProvider client={queryClient}>
      <AdminOnly>
        <BrowserRouter>
          <PortalProvider value='data-ec-umig'>
            <App />
          </PortalProvider>
        </BrowserRouter>
      </AdminOnly>
    </QueryClientProvider>
  </ApolloProvider>
)

export { App, AppBootstrap }
