import * as React from 'react'
import { useState } from 'react'

import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ListItem, MenuDropdown } from '@toasttab/buffet-pui-dropdowns'
import {
  AutorenewIcon,
  LinkIcon,
  MoreVertIcon,
  ReportingAnalyticsIcon,
  UnlinkIcon
} from '@toasttab/buffet-pui-icons'
import { PayrollCustomerUser } from '@local/api/generated/gql/graphql'
import { Modals, UserActionsModal } from '../../operations'

type Props = {
  user?: PayrollCustomerUser
}

const UserActions = (props: Props) => {
  const { user } = props
  const [modal, setModal] = useState<Modals>(undefined)

  return (
    <>
      <MenuDropdown
        renderToggle={(props) => (
          <IconButton icon={<MoreVertIcon aria-label='Menu' />} {...props} />
        )}
      >
        <ListItem
          onClick={() => setModal('link')}
          icon={<LinkIcon accessibility='decorative' />}
          label='Link'
        />
        <ListItem
          onClick={() => setModal('unlink')}
          icon={<UnlinkIcon accessibility='decorative' />}
          label='Unlink'
        />
        <ListItem
          onClick={() => setModal('sync')}
          icon={<AutorenewIcon accessibility='decorative' />}
          label='Sync'
        />
        <ListItem
          onClick={() => setModal('events')}
          icon={<ReportingAnalyticsIcon accessibility='decorative' />}
          label='Events'
        />
      </MenuDropdown>

      <UserActionsModal
        modal={modal}
        onClose={() => setModal(undefined)}
        user={user!}
      />
    </>
  )
}

export { UserActions }
