import { graphql } from '../generated/gql'

export const PayrollCustomerUsers = graphql(/* GraphQL */ `
  query PayrollCustomerUsers($input: PayrollCustomerUsersInput) {
    payrollCustomerUsers(input: $input) {
      id
      shardName
      username
      email
      customerId
      toastIdentityGuid
      companyCode
      customerName
    }
  }
`)

export const LinkPayrollUser = graphql(/* GraphQL */ `
  mutation LinkPayrollUser(
    $customerId: ID!
    $userId: ID!
    $toastIdentityGuid: ID!
  ) {
    payrollUser {
      link(
        customerId: $customerId
        userId: $userId
        toastIdentityGuid: $toastIdentityGuid
      ) {
        __typename
        ... on PayrollUser {
          id
          toastIdentityGuid
          email
          username
          employeeId
          customerId
        }
      }
    }
  }
`)

export const UnlinkPayrollUser = graphql(/* GraphQL */ `
  mutation UnlinkPayrollUser($customerId: ID!, $userId: ID!) {
    payrollUser {
      unlink(customerId: $customerId, userId: $userId) {
        __typename
        ... on PayrollUser {
          id
          toastIdentityGuid
          email
          username
          employeeId
          customerId
        }
      }
    }
  }
`)

export const FindUserEvents = graphql(/* GraphQL */ `
  query findUserEventsByUserId(
    $userId: ID!
    $fromVersion: Long
    $maxNumResults: Int
  ) {
    findUserEventsByUserId(
      input: {
        userId: $userId
        fromVersion: $fromVersion
        maxNumResults: $maxNumResults
      }
    ) {
      __typename
      ... on UserLinkChanged {
        userId
        customerId
        actorId
        oldToastIdentityGuid
        newToastIdentityGuid
        version
        timestamp
      }
      ... on UserLinked {
        userId
        customerId
        actorId
        toastIdentityGuid
        version
        timestamp
      }
      ... on UsernameChanged {
        userId
        customerId
        actorId
        fromUsername
        toUsername
        version
        timestamp
      }
      ... on UsernameChangedWithConflict {
        userId
        customerId
        actorId
        fromUsername
        toUsername
        conflictingUserId
        version
        timestamp
      }
      ... on UserRoleAdded {
        customerId
        userId
        role
        version
        timestamp
      }
      ... on UserRoleRemoved {
        customerId
        userId
        role
        version
        timestamp
      }
      ... on UserUnlinked {
        userId
        customerId
        actorId
        toastIdentityGuid
        version
        timestamp
      }
    }
  }
`)
