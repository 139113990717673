import React from 'react'
import { useState } from 'react'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { CancelButton } from '@toasttab/buffet-pui-forms'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import { FormikValues, useFormik, FormikProvider, Form } from 'formik'

const getErrorMessage = (err: any) => {
  if (!err) return undefined
  if (typeof err === 'string') return err
  if (err?.status && err?.message) return `${err?.status} - ${err?.message}`
  if (typeof err?.message === 'string') return err.message
  return `${err}`
}

export type FormProps<FormData extends FormikValues> = {
  onDone: () => void
  onSave: (data: FormData) => Promise<void>
  children: React.ReactNode
  formId: string
  title: string
  initialValues: FormData
}

export function GenericForm<FormData extends FormikValues>(
  props: FormProps<FormData>
) {
  const { onDone, onSave, children, formId, title, initialValues } = props

  const [error, setError] = useState<any>()

  const formik = useFormik<FormData>({
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      setError(undefined)
      try {
        await onSave(values)
        onDone()
      } catch (err) {
        console.log('sync user submit error', { error: err })
        setError(err)
      }

      setSubmitting(false)
    }
  })

  const errorText = getErrorMessage(error)

  return (
    <Modal
      isOpen={true}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onDone}
    >
      <FormikProvider value={formik}>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody>
          {errorText && (
            <div className='py-2'>
              <div className='p-2 border border-dashed bg-error-0 border-error text-error rounded'>
                {errorText}
              </div>
            </div>
          )}
          <Form id={formId}>
            <div className='flex flex-col gap-4'>{children}</div>
          </Form>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <CancelButton onClick={() => onDone()}>Cancel</CancelButton>
            <Button
              onClick={() => formik.handleSubmit()}
              testId='submit'
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </FormikProvider>
    </Modal>
  )
}
