import * as React from 'react'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { GenericForm } from '../components/generic-form'
import { useMutation } from '@apollo/client'
import { LinkPayrollUserDocument } from '@local/api/generated/gql/graphql'

type Values = {
  userId: string
  customerId: string
  toastIdentityGuid: string
}

type Props = {
  onClose: () => void
  initialValues?: Partial<Values>
}

const LinkModal = (props: Props) => {
  const { onClose, initialValues: init } = props

  const [mutate] = useMutation(LinkPayrollUserDocument, {
    refetchQueries: ['PayrollCustomerUsers']
  })

  const initialValues: Values = {
    userId: init?.userId || '',
    customerId: init?.customerId || '',
    toastIdentityGuid: init?.toastIdentityGuid || ''
  }

  return (
    <GenericForm
      onDone={onClose}
      onSave={async (variables) => {
        await mutate({ variables })

        onClose()
      }}
      formId='link-form'
      title='Link user'
      initialValues={initialValues}
    >
      <TextInputField
        testId='payrollUserUuid'
        name='userId'
        label='Payroll User UUID'
        disabled={!!init?.userId}
      />
      <TextInputField
        testId='payrollCustomerUuid'
        name='customerId'
        label='Payroll Customer UUID'
        disabled={!!init?.customerId}
      />
      <TextInputField
        testId='toastIdentityGuid'
        name='toastIdentityGuid'
        label='Toast Identity Guid'
        disabled={!!init?.toastIdentityGuid}
      />
    </GenericForm>
  )
}

export { LinkModal }
