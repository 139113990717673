import * as React from 'react'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { GenericForm } from '../components/generic-form'
import { useMutation } from '@apollo/client'
import { UnlinkPayrollUserDocument } from '@local/api/generated/gql/graphql'

type Values = {
  userId: string
  customerId: string
}

type Props = {
  onClose: () => void
  initialValues?: Partial<Values>
}

const UnlinkModal = (props: Props) => {
  const { onClose, initialValues: init } = props

  const [mutate] = useMutation(UnlinkPayrollUserDocument, {
    refetchQueries: ['PayrollCustomerUsers']
  })

  const initialValues: Values = {
    userId: init?.userId || '',
    customerId: init?.customerId || ''
  }

  return (
    <GenericForm
      onDone={onClose}
      onSave={async (variables) => {
        const { customerId, userId } = variables

        await mutate({ variables: { userId, customerId } })

        onClose()
      }}
      formId='unlink-form'
      title='Unlink user'
      initialValues={initialValues}
    >
      <TextInputField
        testId='payrollUserUuid'
        name='userId'
        label='Payroll User UUID'
        disabled={!!init?.userId}
      />
      <TextInputField
        testId='payrollCustomerUuid'
        name='customerId'
        label='Payroll Customer UUID'
        disabled={!!init?.customerId}
      />
    </GenericForm>
  )
}

export { UnlinkModal }
