import * as React from 'react'
import { RouteObject, Outlet, Navigate } from 'react-router-dom'

import { UserDebugList } from './list/UserDebugList'

import { UserDebugLayout } from './layout/UserDebugLayout'

const UserDebugRoutes: RouteObject = {
  path: '/user/debug',
  id: 'debug',
  element: (
    <UserDebugLayout>
      <Outlet />
    </UserDebugLayout>
  ),
  children: [
    {
      index: true,
      element: <UserDebugList />
    },
    {
      path: '*',
      element: <Navigate to='.' />
    }
  ]
}

export { UserDebugRoutes }
