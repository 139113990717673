import { useMutation } from '@tanstack/react-query'
import { apiCall } from './apiCall'

type Variables = {
  username: string
}

const useSyncUserMutation = () => {
  const mutation = useMutation({
    mutationFn: async (variables: Variables) => {
      const { username } = variables

      await apiCall({
        url: '/customer-user-migration-by-username',
        method: 'POST',
        body: {
          usernames: [username]
        }
      })
    }
  })

  return mutation
}

export { useSyncUserMutation }
