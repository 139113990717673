import * as React from 'react'
import { useState } from 'react'
import { EcPageHeader } from '@toasttab/ec-ui-page-header'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { AutorenewIcon, LinkIcon, UnlinkIcon } from '@toasttab/buffet-pui-icons'

import { LinkModal, UnlinkModal, SyncModal } from '../operations'

type Props = {
  children: React.ReactNode
}

function UserDebugLayout(props: Props) {
  const { children } = props

  const [linkOpen, setLinkOpen] = useState<boolean>(false)
  const [unlinkOpen, setUnlinkOpen] = useState<boolean>(false)
  const [syncOpen, setSyncOpen] = useState<boolean>(false)

  const actions = (
    <ButtonGroup>
      <Button
        variant='link'
        iconRight={<LinkIcon accessibility='decorative' />}
        type='button'
        onClick={() => setLinkOpen(true)}
      >
        Link User
      </Button>
      <Button
        variant='link'
        iconRight={<UnlinkIcon accessibility='decorative' />}
        type='button'
        onClick={() => setUnlinkOpen(true)}
      >
        Unlink User
      </Button>
      <Button
        iconRight={<AutorenewIcon accessibility='decorative' />}
        type='button'
        onClick={() => setSyncOpen(true)}
      >
        Sync User
      </Button>
    </ButtonGroup>
  )
  return (
    <>
      <EcPageHeader title='User debug' actions={actions} />

      {children}

      {linkOpen && <LinkModal onClose={() => setLinkOpen(false)} />}

      {unlinkOpen && <UnlinkModal onClose={() => setUnlinkOpen(false)} />}

      {syncOpen && <SyncModal onClose={() => setSyncOpen(false)} />}
    </>
  )
}

export { UserDebugLayout }
