import * as React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useTable } from 'react-table'

import {
  Body,
  Cell,
  CellHeading,
  Column,
  Head,
  Row,
  TabulatedDataCard
} from '@toasttab/buffet-pui-tabulated-data-card'

import {
  PayrollCustomerUser,
  PayrollCustomerUsersDocument,
  PayrollCustomerUsersQuery,
  PayrollCustomerUsersQueryVariables
} from '@local/api/generated/gql/graphql'
import { QueryHookOptions, useLazyQuery } from '@apollo/client'
import size from 'lodash/size'
import { UserActions } from './UserActions'

const COLUMNS: Column[] = [
  {
    Header: 'Username',
    className: 'min-w-table-cell-main font-semibold',
    id: 'data-table-name',
    accessor: (a) => a,
    width: '',
    Cell: ({ value }: { value: PayrollCustomerUser }) => {
      return (
        <div className='w-full flex flex-row justify-between items-center'>
          <span>{value.username}</span>
          <UserActions user={value} />
        </div>
      )
    }
  },
  {
    Header: 'User UUID',
    accessor: 'id',
    id: 'data-table-id',
    width: '2fr'
  },
  {
    Header: 'Email',
    accessor: 'email',
    id: 'data-table-email',
    width: '2fr'
  },
  {
    Header: 'Toast Identity Guid',
    accessor: 'toastIdentityGuid',
    id: 'data-table-toast-identity-guid',
    width: '2fr'
  },
  {
    Header: 'Shard',
    accessor: 'shardName',
    id: 'data-table-shard',
    width: '1fr'
  },
  {
    Header: 'Customer ID',
    accessor: 'customerId',
    id: 'data-table-customer-id',
    width: '1fr'
  },
  {
    Header: 'Company Code',
    accessor: 'companyCode',
    id: 'data-table-comapany-code',
    width: '2fr'
  },
  {
    Header: 'Customer Name',
    accessor: 'customerName',
    id: 'data-table-customer-name',
    width: '2fr'
  }
]

export const usePayrollCustomerUsers = (
  options?: QueryHookOptions<
    PayrollCustomerUsersQuery,
    PayrollCustomerUsersQueryVariables
  >
) => useLazyQuery(PayrollCustomerUsersDocument, options)

type Props = {
  dbUsers: PayrollCustomerUser[] | undefined
  dexUsers: PayrollCustomerUser[] | undefined
  isDbLoading?: boolean
  isDexLoading?: boolean
  searchTerm: string
}

const UsersTables = (props: Props) => {
  const { dbUsers, dexUsers, isDbLoading, isDexLoading, searchTerm } = props
  return (
    <>
      <UsersTable
        users={dbUsers}
        isLoading={isDbLoading}
        searchTerm={searchTerm}
        title='Payroll Database Users'
      />
      <UsersTable
        users={dexUsers}
        isLoading={isDexLoading}
        searchTerm={searchTerm}
        title='DynamoDB Users'
      />
    </>
  )
}

const Message = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='flex flex-col items-center py-8 space-y-8'>{children}</div>
  )
}

type TableProps = {
  searchTerm: string
  users?: PayrollCustomerUser[]
  isLoading?: boolean
  title: string
}

const UsersTable = ({ searchTerm, users, isLoading, title }: TableProps) => {
  return (
    <>
      <h2>{title}</h2>
      <div>Records: {isLoading ? 'Loading...' : users?.length}</div>
      {(() => {
        if (isLoading) {
          return <MerryGoRound size='xs' />
        }

        if (!searchTerm) {
          return <Message>Enter a search term to find users</Message>
        }

        if (!users || size(users) <= 0) {
          return <Message>No results for '{searchTerm}'</Message>
        }

        return <MultipleColumnContent data={users} />
      })()}
    </>
  )
}

const ITEMS_PER_ROW = 4

function MultipleColumnContent({ data }: { data: PayrollCustomerUser[] }) {
  const {
    getTableProps, //
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns: COLUMNS, data })

  return (
    <>
      <TabulatedDataCard {...getTableProps()}>
        <Head>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={`row-${headerGroupIndex}`}
            >
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps({
                    className: column.className
                  })}
                  key={`row-${headerGroupIndex}-col-${columnIndex}`}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </Head>
        <Body {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row)
            return (
              <Row
                {...row.getRowProps()}
                columns={COLUMNS}
                itemsPerRow={ITEMS_PER_ROW}
                key={`row-${rowIndex}`}
              >
                {row.cells.map((cell, i) => {
                  const isMainColumn = i === 0
                  return (
                    <Cell
                      {...cell.getCellProps({
                        className: cell.column.className
                      })}
                      itemsPerRow={ITEMS_PER_ROW}
                      isFullWidth={isMainColumn}
                      key={`row-${rowIndex}-cell-${i}`}
                    >
                      {!isMainColumn && (
                        <CellHeading className={cell.column.className}>
                          {cell.column.render('Header')}
                        </CellHeading>
                      )}
                      {cell.render('Cell')}
                    </Cell>
                  )
                })}
              </Row>
            )
          })}
        </Body>
      </TabulatedDataCard>
    </>
  )
}

export { UsersTables }
