import { graphql } from '../generated/gql'

export const Permissions = graphql(/* GraphQL */ `
  query actorPermissionsForEmployees($employeeIds: [ID!]!) {
    actorPermissionsForEmployees(employeeIds: $employeeIds) {
      employeeId
      permissions
    }
  }
`)

export const ListMigrations = graphql(/* GraphQL */ `
  query ListMigrations {
    migrations {
      customerUuid
      userUuid
      companyCode
      status
      error
      importId
      updatedAt
    }
  }
`)

export const CreateMigration = graphql(/* GraphQL */ `
  mutation CreateMigration($input: UserMigrationInput!) {
    migrateUsers(input: $input) {
      customerUuid
      userUuid
      companyCode
      status
      error
      importId
      updatedAt
    }
  }
`)

export const DeleteMigration = graphql(/* GraphQL */ `
  mutation DeleteMigration($importId: ID!) {
    deleteUserMigration(importId: $importId) {
      customerUuid
      userUuid
      companyCode
      status
      error
      importId
      updatedAt
    }
  }
`)

export const EmployeeDetail = graphql(/* GraphQL */ `
  query EmployeeDetail($userId: ID!) {
    findUserById(userId: $userId) {
      id
      email
      employeeUuid
      name {
        formatted
      }
    }
  }
`)

export const AccessibleCustomersQuery = graphql(/* GraphQL */ `
  query AccessibleCustomers($input: AccessibleCustomersInput!) {
    accessibleCustomers(input: $input) {
      customers {
        companyCode
        shardName
        name
      }
      nextCursor
    }
  }
`)
