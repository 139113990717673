import * as React from 'react'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { FindUserEvents } from '@local/api/documents'
import { useQuery } from '@apollo/client'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Alert } from '@toasttab/buffet-pui-alerts'

const Event = (props: { key: string; event: any }) => {
  const { key, event } = props
  const timestamp = format(
    new Date(event['timestamp']),
    Formats.dateTime.medium_with_seconds
  )

  return (
    <div className='pb-6 pt-3'>
      <div className='type-headline-5 mb-2'>{event.__typename}</div>
      <div>
        <strong>Timestamp</strong>: {timestamp}
      </div>
      {Object.keys(event)
        .filter(
          (property) => property !== '__typename' && property !== 'timestamp'
        )
        .map((property: any, index: number) => {
          return (
            <div key={`${key}-prop-${index}`}>
              <strong className='capitalize'>{property}</strong>:{' '}
              {event[property]}
            </div>
          )
        })}
    </div>
  )
}

const Events = (props: {
  events: any[] | undefined
  loading: boolean
  error?: string
}) => {
  const { events, loading, error } = props

  if (loading) {
    return <MerryGoRound />
  } else if (error) {
    return (
      <Alert title='' variant='error'>
        Could not get user events: ${error}
      </Alert>
    )
  } else if (events === undefined || events.length === 0) {
    return <div>No events found</div>
  } else {
    return (
      <div className='divide-y'>
        {events.map((event: any, index) => (
          <Event key={index.toString()} event={event} />
        ))}
      </div>
    )
  }
}

export const EventsModal = (props: { onClose: () => void; userId: string }) => {
  const { onClose, userId } = props

  const { data, loading, error, client } = useQuery(FindUserEvents, {
    variables: { userId }
  })

  React.useEffect(() => {
    return () => {
      client.cache.evict({ fieldName: 'findUserEventsByUserId' })
    }
  }, [])

  return (
    <Modal
      size='xl'
      isOpen={true}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
      position='pin-right'
    >
      <ModalHeader>User Events</ModalHeader>

      <ModalBody>
        <Events
          events={data?.findUserEventsByUserId}
          loading={loading}
          error={error?.message}
        />
      </ModalBody>
    </Modal>
  )
}
