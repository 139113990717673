import * as React from 'react'
import { PayrollCustomerUser } from '@local/api/generated/gql/graphql'
import { UserComparisonTable } from './UserComparisonTable'
import groupBy from 'lodash/groupBy'
import keys from 'lodash/keys'
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import first from 'lodash/first'
import size from 'lodash/size'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

type Props = {
  dbUsers: PayrollCustomerUser[] | undefined
  dexUsers: PayrollCustomerUser[] | undefined
  isDbLoading?: boolean
  isDexLoading?: boolean
  searchTerm: string
}

const UsersComparisonTables = (props: Props) => {
  const { dbUsers, dexUsers, isDbLoading, isDexLoading, searchTerm } = props

  const dbUsersByKey = groupBy(dbUsers, (item) => {
    const { shardName, companyCode, id } = item
    return `${shardName}-${companyCode}-${id}`
  })
  const dexUsersByKey = groupBy(dexUsers, (item) => {
    const { shardName, companyCode, id } = item
    return `${shardName}-${companyCode}-${id}`
  })

  const userKeys = uniq([...keys(dbUsersByKey), ...keys(dexUsersByKey)])

  const userData = userKeys.map((key) => {
    const dbUsers = dbUsersByKey[key]
    const dexUsers = dexUsersByKey[key]
    return { key, dbUsers, dexUsers }
  })

  const tables = map(userData, (data) => {
    const { key, dbUsers, dexUsers } = data
    return (
      <UserComparisonTable
        key={key}
        dbUser={first(dbUsers)}
        dexUser={first(dexUsers)}
        isDbLoading={isDbLoading}
        isDexLoading={isDexLoading}
      />
    )
  })

  if (size(tables) <= 0) {
    if (!searchTerm) {
      return (
        <div className='text-center type-headline-3'>Enter a search term</div>
      )
    }
    if (isDbLoading || isDexLoading) {
      return (
        <>
          <MerryGoRound />
          <div className='text-center type-headline-3'>Loading...</div>
        </>
      )
    }

    return (
      <div className='text-center type-headline-3'>
        No Results for '{searchTerm}'
      </div>
    )
  }

  return <div className='flex flex-col py-4 gap-y-4'>{tables}</div>
}

export { UsersComparisonTables }
